export const commonProps = {
  year: '2020',
  make: 'Honda',
  model: 'Accord',
  bodyStyle: 'Sport Sedan 4D',
  analyticsDetailedPageName: 'ownership_model_auto-repair',
  analyticsSuperSection: 'ownership',
  assistChannel: 'KBB',
  assistProduct: 'Repair Pricing & Cost Estimates',
  analyticsSectionName: 'ownership',
  eventSource: 'dealership',
  vehicleType: 'used',
  assistSubchannel: 'model auto-repair',
  chromeStyleId: '410993',
  searchFacetZipCode: '20146',
  wspCode: 'KBB',
  listingCode: undefined,
};

export const commonPropsNoVehicle = {
  analyticsDetailedPageName: 'ownership_model_auto-repair',
  analyticsSuperSection: 'ownership',
  assistChannel: 'KBB',
  assistProduct: 'Repair Pricing & Cost Estimates',
  analyticsSectionName: 'ownership',
  eventSource: 'dealership',
  vehicleType: 'used',
  assistSubchannel: 'model auto-repair',
  searchFacetZipCode: '20146',
  pageType: 'searchrepair',
  eventType: 'click',
  wspCode: 'KBB',
  listingCode: undefined,
};

export const saveMyCarCommonProps = {
  year: '2020',
  make: 'Honda',
  model: 'Accord',
  bodyStyle: 'Sport Sedan 4D',
  analyticsDetailedPageName: 'ownership_model_auto-repair',
  analyticsSuperSection: 'ownership',
  assistChannel: 'KBB',
  assistProduct: 'Repair Pricing & Cost Estimates',
  analyticsSectionName: 'ownership',
  eventSource: 'vehicle',
  vehicleType: 'used',
  assistSubchannel: 'model auto-repair',
  chromeStyleId: '410993',
  vehicleId: '444920',
  searchFacetZipCode: '20146',
  wspCode: 'KBB'
};

export const saveMyCarCommonPropsWithVin = {
  year: '2020',
  make: 'Honda',
  model: 'Accord',
  bodyStyle: 'Sport Sedan 4D',
  analyticsDetailedPageName: 'ownership_model_auto-repair',
  analyticsSuperSection: 'ownership',
  assistChannel: 'KBB',
  assistProduct: 'Repair Pricing & Cost Estimates',
  analyticsSectionName: 'ownership',
  eventSource: 'vehicle',
  vehicleType: 'used',
  assistSubchannel: 'model auto-repair',
  chromeStyleId: '410993',
  vehicleId: '444920',
  searchFacetZipCode: '20146',
  wspCode: 'KBB',
  vin: '12345'
};

const mileageRange = '48-60K';
const commonService = 'oil-change';
const saveEvent = 'logged-in';

export const clicks = {
  recallBlasterCallLinkClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'recall_blaster_call_link',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'FFB3'
              },
              service: {
                customerSource: 'crm'
              }
            }
          },
          ...commonPropsNoVehicle,
          eventSource: 'lead'
        }
      }
    }
  },
  recallBlasterSendRequest: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'recall_blaster_send_request',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'CC30'
              }
            }
          },
          ...commonPropsNoVehicle,          
          eventSource: 'lead'
        }
      }
    }
  },
  recallBlasterCallLinkSatClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'recall_blaster_call_link_sat',
          pageType: 'searchrepair',
          eventType: 'click',
          cdl: {   
            eventProperties: {
              service: {
                customerSource: 'crm'
              },
              event: {
                valueEventCode: 'FFB3'
              },
            }
          },
          ...commonProps
        }
      }
    }
  },
  getFutureRecallAlertsClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'get_future_recall_alerts',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  getFutureRecallAlertsSubmit: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'get_future_recall_alerts',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: '39F5'
              }
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  isMyCarAffectedClickForVin: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'is_my_car_affected_vin',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'CC30'
              }
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  isMyCarAffectedClickForLp: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'is_my_car_affected_lp',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'CC30'
              }
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  dealerLinkClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'dealer_link_412528',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'FX44'
              },
              service: {
                customerSource: 'byc'
              }
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  serviceOfferDealerLinkClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'service-offer_dealer_link_412528',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'FX44'
              },
              service: {
                customerSource: 'crm'
              }
            }
          },
          ...commonPropsNoVehicle,
          eventSource: 'lead'
        }
      }
    }
  },
  promoEmailShareClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'promo_email_share',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  obd2GetFutureRecallAlertClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'obd2_get_future_recall_alert',
          pageType: 'searchrepair',
          eventType: 'click',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  repairPricingClickProps: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'repair_pricing',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  dealerCardTileClickProps: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'dealer_tile_click',
          cdl: {   
            eventProperties: {
              service: {
                customerSource: 'crm'
              }
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  dealerCardPhoneLinkClickProps: {    
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'lead_phone',
          ...commonPropsNoVehicle,
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'FFB3'
              },
              service: {
                customerSource: 'byc'
              }
            }
          },
          eventSource: 'lead'
        }
      }
    }
  },
  slideshowSliderClickProps: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta_service_appt_tile_click',
          pageType: 'searchrepair',
          eventType: 'click',
          ...commonProps
        }
      }
    }
  },
  dealerCardCheckAvailabilityClickProps: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'check_availability',
          cdl: {   
            eventProperties: {
              service: {
                customerSource: 'crm'
              }
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  dealerCardGetEstimateClickProps: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'get_estimate',
          cdl: {   
            eventProperties: {
              service: {
                customerSource: 'byc'
              }
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  selectVehicleProps: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'select_vehicle',
          pageType: 'searchrepair',
          eventType: 'click',
          ...commonProps
        }
      }
    }
  },
  bookServiceAppointmentClickProps: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'book_service_appointment',
          cdl: {   
            eventProperties: {
              service: {
                customerSource: 'crm'
              },
              event: {
                valueEventCode: 'EBE9'
              }
            }
          },
          ...commonPropsNoVehicle,
          eventSource: 'lead'
        }
      }
    }
  },
  getSmartAdviceClickYmm: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'get-smart-service-advice_ymm',
          pageType: 'searchrepair',
          eventType: 'click',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  getSmartAdviceClickVin: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'get-smart-service-advice_vin',
          pageType: 'searchrepair',
          eventType: 'click',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  getSmartAdviceClickLp: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'get-smart-service-advice_lp',
          pageType: 'searchrepair',
          eventType: 'click',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  serviceOfferClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'service-offer_select-dealer',
          cdl: {   
            eventProperties: {
              service: {
                customerSource: 'crm'
              }
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  checkMyRiskOfMajorRepairClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'check-my-risk-of-major-repair_oil-change',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  dealerServiceOfferEmailSendClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'lead_email',
          cdl: {   
            eventProperties: {
              service: {
                customerSource: 'crm'
              },
              event: {
                valueEventCode: 'FC1C'
              }
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  serviceOfferCallLinkClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'service-offer_call_link',
          cdl: {   
            eventProperties: {
              service: {
                customerSource: 'crm'
              },
              event: {
                valueEventCode: 'FFB3'
              }
            }
          },
          ...commonPropsNoVehicle,
          eventSource: 'lead'
        }
      }
    }
  },
  lcpSelectVehicleProps: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'select_vehicle',
          pageType: 'searchrepair',
          eventType: 'click',
          ...commonProps
        }
      }
    }
  },
  getServiceEstimatesClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'get-service-estimates',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  serviceQuoteSelectServiceClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'service-quote_select_service',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  serviceQuoteSendRequestClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'service-quote_send_request::no-list',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'FX44'
              }
            }
          },
          ...commonPropsNoVehicle,
          eventSource: 'lead'
        }
      }
    }
  },
  serviceQuoteCallLinkClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'service-quote_call_link',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'FFB3'
              },
              service: {
                customerSource: 'Xtime'
              }
            }
          },
          ...commonPropsNoVehicle,
          eventSource: 'lead'
        }
      }
    }
  },
  seeServicePricingClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'see-service-pricing',
          pageType: 'searchrepair',
          eventType: 'click',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  mostCommonServicesClick: {
    commonService,
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: `most-common-services::${commonService}`,
          pageType: 'searchrepair',
          eventType: 'click',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  getMyAnswerClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'get-my-answer',
          pageType: 'searchrepair',
          eventType: 'click',
          ...commonProps
        }
      }
    }
  },
  checkMyCarForRecallsClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'check-my-car-for-recalls',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'CC30'
              }
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  repairCostForecastDropdownClick: {
    mileageRange,
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: `mileage_your-repair-forecast_${mileageRange}`,
          pageType: 'searchrepair',
          eventType: 'click',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  saveMyCarSaveVehicleClick: {
    saveEvent,
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: `save_${saveEvent}-vehicle`,
          pageType: 'searchrepair',
          eventType: 'save',
          ...saveMyCarCommonProps
        }
      }
    }
  },
  saveMyCarDeleteVehicleClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'remove_saved-vehicle',
          pageType: 'searchrepair',
          eventType: 'save',
          ...saveMyCarCommonProps
        }
      }
    }
  },
  saveMyCarSaveVehicleClickWithVin: {
    saveEvent,
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: `save_${saveEvent}-vehicle`,
          pageType: 'searchrepair',
          eventType: 'save',
          ...saveMyCarCommonPropsWithVin
        }
      }
    }
  },
  saveMyCarDeleteVehicleClickWithVin: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'remove_saved-vehicle',
          pageType: 'searchrepair',
          eventType: 'save',
          ...saveMyCarCommonPropsWithVin
        }
      }
    }
  },
  recallAlertsSmsConsentClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'sms-consent',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  saveServiceAppointmentClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta::service-appt::save',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  seeCommonProblemsClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'text-link::see-common-problems',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  askAQuestionClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'ask-a-question',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  seeServiceAndRepairPricingClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'see-service-and-repair-pricing',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  dropDownRepairClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          ...commonPropsNoVehicle
        }
      }
    }
  },
  dealerRepairPricingAccordionClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'dlr_repair-pricing_acr',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  independentRepairPricingAccordionClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'ind_repair-pricing_acr',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  serviceQuoteGetEstimatesClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta::get-service-quote',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  nextScheduledServiceClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta_50000-mile-service',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  dealerCardPromoLinkClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'promo-text-link::promo-text',
          cdl: {   
            eventProperties: {
              service: {
                customerSource: 'byc'
              },
              event: {
                valueEventCode: 'FC1C'
              },
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  dealerCardPromoEmailShareClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta::dealer-promo-modal::email-share',
          cdl: {   
            eventProperties: {
              service: {
                customerSource: 'byc'
              },
              event: {
                valueEventCode: 'FC1C'
              },
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  sorryAboutThatCallClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'lead_phone_list_sat',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'FFB3'
              },
              service: {
                customerSource: 'crm'
              }
            }
          },
          ...commonPropsNoVehicle,
          eventSource: 'lead'
        }
      }
    }
  },
  vehicleSpecificRecallsClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'tile::expand::specific::vehicle-recall-test-title',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  recallsLatestRecallsClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'tile::expand::recall-test-title',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  specificCommonServicesAndRepairsClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'common-problems::oil-change',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  safetyRecallsFoundClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: `cta::3_safety-recalls-found`,
          ...commonPropsNoVehicle
        }
      }
    }
  },
  commonProblemsFoundClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: `cta::3_common-problems-found`,
          ...commonPropsNoVehicle
        }
      }
    }
  },
  seeServicePriceClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta::see-service-pricing',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  findServiceCenterClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta::find-a-service-center',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  priceAnotherCarRepairsClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta::price-another-car-repair',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  checkAnotherCarRecallsClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta::check-another-car-for-recalls',
          cdl: {   
            eventProperties: {
              event: {
                valueEventCode: 'CC30'
              }
            }
          },
          ...commonPropsNoVehicle
        }
      }
    }
  },
  getFutureRecallAlertClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta::alert::get-future-recall-alert::email',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  signUpForFutureRecallAlertClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'sign-up-for-future-recall-alert::checkbox',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  offersAndCuponsScrollUpClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta::offers-coupons::scroll-up',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  obd2AccordionClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta::obd-2::acc',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  serviceAdvisorAccordionClick: {
    expected: {
      type: 'click',
      pixall: {
        type: 'click',
        props: {
          label: 'cta::service-faq::acc',
          ...commonPropsNoVehicle
        }
      }
    }
  },
  dealerCardImpression: {
    expected: {
      type: 'impression',
      pixall: {
        type: 'impression',
        props: {
          label: 'dealer_tile_impression',
          ...commonPropsNoVehicle,
          eventType: 'impression'
        }
      }
    }
  },
  phoneCallCtaImpression: {
    expected: {
      type: 'impression',
      pixall: {
        type: 'impression',
        props: {
          label: 'phone-call::cta::list',
          cdl: {   
            eventProperties: {
              service: {
                customerSource: 'crm'
              }
            }
          },
          ...commonPropsNoVehicle,
          eventType: 'impression'
        }
      }
    }
  },
};
export const mockDataLayer = {
  googleanalytics: {
    vehicle: {
      chromeStyleId: '410993'
    }
  },
  analytics: {
    detailpagename: 'ownership_model_auto-repair',
    supersectionname: 'ownership',
    sectionname: 'ownership'
  },
  location: {
    zip: '20146'
  },
  info: {
    yearid: '2020',
    manufacturer: {
      name: 'Honda'
    },
    model: {
      name: 'Accord'
    },
    vehicle: {
      name: 'Sport Sedan 4D'
    },
    intent: 'used',
    chromeStyleIds: '410993'
  }
};
