import { getGAPageProps } from './getGAPageProps';

//******* Do not modify the code below this line without consulting with both teams
const toGtmString = (value) => (value ? value.toString() : undefined);

const ga = {
  getEvent: (event) => {
    return {
      googleAnalytics: {
        ...event
      }
    };
  },
  fireClick: (analytics, event) => {
    // eslint-disable-next-line no-unused-vars
    analytics.push((dataLayer) => ({
      type: 'click',
      ...ga.getEvent(event)
    }));
  },
  fireImpression: (analytics, event) => {
    // eslint-disable-next-line no-unused-vars
    analytics.push((dataLayer) => ({
      type: 'impression',
      ...ga.getEvent(event)
    }));
  },
  serviceAndRepairDefaults: {
    vehicle: {
      catalog: 'kbb',
      condition: 'used'
    }
  },
  normalizedString: (string) => {
    return string
      ? string
        .replace(/\s+|\/+/g, '-') // Remove white space with '-'
        .replace(/[,:]/g, '_') // Remove , with '_'
        .toLowerCase() // Change to lowercase
      : '';
  },
  normalizedStringWithCharLimit: (string = '', charLimit = 25) => {
    const parsedPromo = string
      .replace(/[.,/#!$%^&*;:{}=\-_`~"()]/g, ' ')
      .split(' ')
      .filter((ele) => ele !== '');

    const normalizedArray = [];
    let charCount = 0;
    parsedPromo.forEach((ele) => {
      const lowerCaseEle = ele.toLowerCase();
      if (charCount >= charLimit) {
        return;
      }
      if (charCount < charLimit) {
        normalizedArray.push(lowerCaseEle.slice(0, charLimit - charCount));
      }
      charCount += ele.length;
    });
    return normalizedArray.join('-');
  },
  mapVehicleInfo: (vehicleInfo) => {
    const getChromeStyleId = (chromeStyleIds) => {
      return chromeStyleIds && chromeStyleIds.length > 0 ? toGtmString(chromeStyleIds[0]) : undefined;
    };

    return {
      year: toGtmString(vehicleInfo?.YearId ?? vehicleInfo?.yearId),
      makeId: toGtmString(vehicleInfo?.MakeId ?? vehicleInfo?.makeId),
      makeName: toGtmString(vehicleInfo?.MakeName ?? vehicleInfo?.makeName),
      modelId: toGtmString(vehicleInfo?.ModelId ?? vehicleInfo?.modelId),
      modelName: toGtmString(vehicleInfo?.ModelName ?? vehicleInfo?.modelName),
      trimName: toGtmString(vehicleInfo?.TrimName ?? vehicleInfo?.trimName),
      vehicleId: toGtmString(vehicleInfo?.VehicleId ?? vehicleInfo?.vehicleId),
      vin: toGtmString(vehicleInfo?.Vin ?? vehicleInfo?.vin),
      chromeStyleId: vehicleInfo?.ChromeStyleId
        ? toGtmString(vehicleInfo?.ChromeStyleId)
        : getChromeStyleId(vehicleInfo?.ChromeStyleIds ?? vehicleInfo?.chromeStyleIds)
    };
  },
  constructVehicle: (vehicleInfo, svocid = null, provider = null, subProvider = null, type = null) => {
    const vehicle = {
      ...ga.mapVehicleInfo(vehicleInfo),
      dealershipId: svocid,
      customerType: provider && provider.toLowerCase(),
      priorityType: type,
      ...ga.serviceAndRepairDefaults.vehicle
    };

    return vehicle;
  },
  removeNullProperties: (obj) => {
    const newObject = { ...obj };
    Object.keys(newObject).forEach((key) => {
      const currentProp = newObject[key];
      if (currentProp === null) {
        delete newObject[key];
      } else if (typeof currentProp === 'object') {
        newObject[key] = ga.removeNullProperties(currentProp);
      }
    });
    return newObject;
  },
  pageData: (routeName, pathName = '', vehicleInfo = null) => {
    const pageData = {
      common: {
        page: getGAPageProps(routeName, pathName)
      },
      vehicle: ga.constructVehicle(vehicleInfo)
    };
    return ga.removeNullProperties(pageData);
  },
  constructEcomData(
    ecomIdName = null,
    ecomList = null,
    type = null,
    provider = null,
    svocId = null,
    distance = null,
    cardIndex = null
  ) {
    return {
      id: ecomIdName,
      name: ecomIdName,
      category: 'ecom_service-repair',
      list: ecomList,
      ...(cardIndex && { dimension25: cardIndex }),
      dimension31: type,
      dimension32: provider && provider.toLowerCase(),
      dimension33: svocId,
      dimension34: distance
    };
  },
  //******* Do not modify the code above this line without consulting with both teams.
  clicks: {
    selectVehicle: (vehicle, nonInteractive = true) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'cta::select-vehicle',
          eventNonInteractive: nonInteractive,
          eventData: {
            vehicle: ga.constructVehicle(vehicle)
          }
        }
      };
    },
    dealerCardTileClick: (vehicle, svocId, provider, subProvider, type) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'cta::tile-click',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId, provider, subProvider, type)
          }
        }
      };
    },
    phoneLinkClick: (vehicle, svocId, provider, subProvider, type) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'lead::phone',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId, provider, subProvider, type)
          }
        }
      };
    },
    checkAvailabilityClick: (vehicle, svocId, provider, subProvider, type) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'cta::service-appt::check-availability',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId, provider, subProvider, type)
          }
        }
      };
    },
    getAnEstimateClick: (vehicle, svocId, provider, subProvider, type) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'cta::get-estimate',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId, provider, subProvider, type)
          }
        }
      };
    },
    dealerCardTileEcomClick: (ecomIdName, type, provider, svocId, distance, cardIndex) => {
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-click',
          eventResult: 'dealer-card::tile-click',
          eventNonInteractive: false,
          ecommerce: {
            click: {
              actionField: {
                list: 'ecom_service-repair'
              },
              products: [ga.constructEcomData(ecomIdName, null, type, provider, svocId, distance, cardIndex)]
            }
          }
        }
      };
    },
    phoneCallEcomClick: (ecomIdName, type, provider, svocId, distance, cardIndex) => {
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-click',
          eventResult: 'phone-call::cta',
          eventNonInteractive: false,
          ecommerce: {
            click: {
              actionField: {
                list: 'ecom_service-repair'
              },
              products: [ga.constructEcomData(ecomIdName, null, type, provider, svocId, distance, cardIndex)]
            }
          }
        }
      };
    },
    scheduleApptEcomClick: (ecomIdName, type, provider, svocId, distance, cardIndex) => {
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-click',
          eventResult: 'dealer-card::schedule-appt',
          eventNonInteractive: false,
          ecommerce: {
            click: {
              actionField: {
                list: 'ecom_service-repair'
              },
              products: [ga.constructEcomData(ecomIdName, null, type, provider, svocId, distance, cardIndex)]
            }
          }
        }
      };
    },
    getAnEstimateEcomClick: (ecomIdName, type, provider, svocId, distance, cardIndex) => {
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-click',
          eventResult: 'dealer-card::get-estimate',
          eventNonInteractive: false,
          ecommerce: {
            click: {
              actionField: {
                list: 'ecom_service-repair'
              },
              products: [ga.constructEcomData(ecomIdName, null, type, provider, svocId, distance, cardIndex)]
            }
          }
        }
      };
    },
    selectVehicleServiceApptClick: (vehicle) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'cta::service-appt::next',
          eventNonInteractive: true,
          eventData: {
            vehicle: ga.constructVehicle(vehicle)
          }
        }
      };
    },
    saveServiceAppointmentClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'cta::service-appt::save',
          eventNonInteractive: true,
          eventData: {}
        }
      };
    },
    bookServiceAppointmentClick: ({ vehicle, svocid, provider, subProvider, type }) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'lead::service-appt',
          eventNonInteractive: true,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocid, provider, subProvider, type)
          }
        }
      };
    },
    askAQuestionTextLinkClick: (vehicle) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service-and-repair', // TODO: Should this be 'service' ?
          eventAction: 'click',
          eventResult: 'text-link::ask-a-question',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle)
          }
        }
      };
    },
    findAServiceCenterTextLinkClick: (vehicle) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service-and-repair', // TODO: Should this be 'service' ?
          eventAction: 'click',
          eventResult: 'text-link::find-a-service-center',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle)
          }
        }
      };
    },
    repairPricingClick: (eventLabel) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `cta::${eventLabel ? `get-my-answer` : `see-pricing`}`,
          eventNonInteractive: false
        }
      };
    },
    nextScheduledServiceClick: (serviceName) => {
      const normalizeServiceName = (name) => {
        // TODO: should use one of the global functions, except if this is a special case with no way of being handled by the global function
        if (name) {
          return name
            .toLowerCase()
            .replace(',', '')
            .replace(/\s+|\/+/g, '-');
        }
        return '';
      };
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `cta::${normalizeServiceName(serviceName)}`,
          eventNonInteractive: true
        }
      };
    },
    recommendedServicesPageNumberClick: (pageNumber) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: `prs::list_scroll_page_${pageNumber}`,
          eventNonInteractive: false
        }
      };
    },
    recommendedServicesPageChevronsClick: (pageNumber, side) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `prs::list_scroll_${side}_page_${pageNumber}`,
          eventNonInteractive: false
        }
      };
    },
    priceCommonRepairsPageNumberClick: (pageNumber) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: `pcr::list_scroll_page_${pageNumber}`,
          eventNonInteractive: false
        }
      };
    },
    priceCommonRepairsPageChevronsClick: (pageNumber, side) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `pcr::list_scroll_${side}_page_${pageNumber}`,
          eventNonInteractive: false
        }
      };
    },
    checkMyCarForRecallsClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'recalls',
          eventAction: 'click',
          eventResult: 'cta::check-my-car-for-recalls',
          eventNonInteractive: false
        }
      };
    },
    getFutureRecallAlertsClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'recalls',
          eventAction: 'click',
          eventResult: 'cta::alert::get-future-recall-alert::email',
          eventNonInteractive: false
        }
      };
    },
    getFutureRecallAlertsSubmit: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'recalls',
          eventAction: 'click',
          eventResult: 'cta::submit::get-future-recall-alert::email',
          eventNonInteractive: false
        }
      };
    },
    phoneClickClick: () => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: '[service|repair]',
          eventAction: 'submit',
          eventResult: 'lead::phone',
          eventNonInteractive: false
        }
      };
    },
    findAServiceCenterClick: (serviceName) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: serviceName,
          eventAction: 'click',
          eventResult: 'cta::find-a-service-center',
          eventNonInteractive: false
        }
      };
    },
    sendMyPricingReportClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'cta::see-my-pricing-report',
          eventNonInteractive: false
        }
      };
    },
    sendMeACopyModuleClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'cta::submit::send-me-a-copy::email',
          eventNonInteractive: false
        }
      };
    },
    saveChangesClick: (vehicle) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'cta::save-changes',
          eventNonInteractive: true,
          eventData: {
            vehicle: ga.constructVehicle(vehicle)
          }
        }
      };
    },
    isMyCarAffectedClick: (source) => {
      // source can be vin or lp
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'recalls',
          eventAction: 'click',
          eventResult: `cta::is-my-car-affected_${source}`,
          eventNonInteractive: true
        }
      };
    },
    vehicleSpecificRecallsClick: (recallTitle) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'recalls',
          eventAction: 'click',
          eventResult: `tile::expand::specific::${ga.normalizedString(recallTitle)}`,
          eventNonInteractive: true
        }
      };
    },
    recallsLatestRecallsClick: (recallTitle) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'recalls',
          eventAction: 'click',
          eventResult: `tile::expand::${ga.normalizedString(recallTitle)}`,
          eventNonInteractive: true
        }
      };
    },
    vehicleSpecificMaintenanceClick: (serviceName) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `text-link::${ga.normalizedString(serviceName)}`,
          eventNonInteractive: true
        }
      };
    },
    specificCommonProblemClick: (serviceName) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `cta::${ga.normalizedString(serviceName)}`,
          eventNonInteractive: true
        }
      };
    },
    seeCommonProblemsClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'text-link::see-common-problems',
          eventNonInteractive: true
        }
      };
    },
    promoTextClick: (promo, { vehicle, svocId, provider, subProvider, type }) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service-and-repair', // TODO: Should this be 'service' ?
          eventAction: 'click',
          eventResult: `promo-text-link::${ga.normalizedStringWithCharLimit(promo, 25)}`,
          eventNonInteractive: true,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId, provider, subProvider, type)
          }
        }
      };
    },
    sendPromoEmailClick: ({ vehicle, svocId, provider, subProvider, type }) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service-and-repair',
          eventAction: 'submit',
          eventResult: 'cta::dealer-promo-modal::email-share',
          eventNonInteractive: true,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId, provider, subProvider, type)
          }
        }
      };
    },
    dealerServiceSchedulingLinkClick: (vehicle, svocid, provider, subProvider, type) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'lead::service-appt::dlr-lnk',
          eventNonInteractive: true,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocid, provider, subProvider, type)
          }
        }
      };
    },
    dealerServiceSchedulingLinkEcomClick: (ecomIdName, type, provider, svocId, distance, cardIndex) => {
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-click',
          eventResult: `schedule-appt::${cardIndex}::dlr-lnk`,
          eventNonInteractive: false,
          ecommerce: {
            click: {
              actionField: {
                list: 'ecom_service-repair'
              },
              products: [ga.constructEcomData(ecomIdName, null, type, provider, svocId, distance, cardIndex)]
            }
          }
        }
      };
    },
    recallBlasterSelectedDealerPhoneClick: (vehicle, svocId, provider, subProvider, type) => {
      return blasterSelectedDealerPhoneClick({ vehicle, svocId, provider, subProvider, type });
    },
    recallBlasterSelectedDealerEcomPhoneClick: (ecomIdName, svocId, provider, type, distance, position) => {
      return blasterSelectedDealerEcomPhoneClick({ ecomIdName, svocId, provider, type, distance, position });
    },
    recallBlasterOptInClick: () => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'sign-up-for-future-recall-alert::checkbox',
          eventNonInteractive: true
        }
      };
    },
    recallBlasterSubmitClick: (hasSelectedDealers) => {
      return blasterSubmitClick({ eventResultName: 'recall', hasSelectedDealers });
    },
    recallBlasterSubmitEcomClick: (ecomIdName, svocId, provider, type, distance, position) => {
      return blasterSubmitEcomClick({
        eventResultName: 'recall',
        ecomIdName,
        svocId,
        provider,
        type,
        distance,
        position
      });
    },
    recallBlasterCheckAnotherCarForRecallsClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'cta::check-another-car-for-recalls',
          eventNonInteractive: true
        }
      };
    },
    recallBlasterCallLinkSatSubmit: (vehicle, svocId, provider, subProvider, type) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'lead::phone::list::sat',
          eventNonInteractive: true,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId, provider, subProvider, type)
          }
        }
      };
    },
    recallBlasterPhoneCallSatEcomClick: (ecomIdName, svocId, provider, type, distance, position) => {
      return blasterPhoneCallSatEcomClick({ ecomIdName, svocId, provider, type, distance, position });
    },
    recallBlasterSeeServicePriceSubmit: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'cta::see::service::price',
          eventNonInteractive: false
        }
      };
    },
    recallBlasterNoDealerFoundSubmit: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'cta::no-recall-blaster-dlr-found::find-local-dlrs::btn',
          eventNonInteractive: false
        }
      };
    },
    serviceQuoteCallLinkSatSubmit: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'lead::phone::service-quote::list::sat',
          eventNonInteractive: false
        }
      };
    },
    serviceQuotePhoneCallSatEcomClick: (ecomIdName, svocId, provider, type, distance, position) => {
      return blasterPhoneCallSatEcomClick({
        eventResultName: 'service',
        ecomIdName,
        svocId,
        provider,
        type,
        distance,
        position
      });
    },
    serviceQuoteSelectServiceNextClick: (eventResultName = 'service-quote') => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `select-service:${eventResultName ? `:${eventResultName}:` : ''}:next`,
          eventNonInteractive: false
        }
      };
    },
    serviceQuoteGetEstimatesClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'cta::get-service-quote',
          eventNonInteractive: false
        }
      };
    },
    serviceQuoteSelectedDealerPhoneClick: (vehicle, svocId, provider, subProvider, type) => {
      return blasterSelectedDealerPhoneClick({
        eventResultName: 'service-quote',
        vehicle,
        svocId,
        provider,
        subProvider,
        type
      });
    },
    serviceOfferSelectedDealerPhoneClick: (vehicle, svocId, provider, type) => {
      return blasterSelectedDealerPhoneClick({
        eventResultName: 'service-offers',
        vehicle,
        svocId,
        provider,
        type
      });
    },
    serviceQuoteCheckMyCarForRecallsClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'cta::check-my-car-for-recalls',
          eventNonInteractive: true
        }
      };
    },
    serviceQuoteSubmitClick: (hasSelectedDealers, eventResultName = 'service-quote') => {
      return blasterSubmitClick({ eventResultName, hasSelectedDealers });
    },
    serviceQuoteSubmitEcomClick: (ecomIdName, svocId, provider, type, distance, position) => {
      return blasterSubmitEcomClick({
        eventResultName: 'service-quote',
        ecomIdName,
        svocId,
        provider,
        type,
        distance,
        position
      });
    },
    serviceQuoteSelectedDealerEcomPhoneClick: (ecomIdName, svocId, provider, type, distance, position) => {
      return blasterSelectedDealerEcomPhoneClick({ ecomIdName, svocId, provider, type, distance, position });
    },
    frrWidgetSelectVehicleClick: (vehicle, svocId) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service_widget',
          eventAction: 'click',
          eventResult: 'cta::select-vehicle::next',
          eventNonInteractive: true,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId)
          }
        }
      };
    },
    frrWidgetEditVehicleClick: (vehicle, svocId) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service_widget',
          eventAction: 'submit',
          eventResult: 'edit::vehicle',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId)
          }
        }
      };
    },
    frrWidgetGetPricingReportClick: (vehicle, svocId) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service_widget',
          eventAction: 'submit',
          eventResult: 'cta::see-my-pricing-report',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId)
          }
        }
      };
    },
    frrWidgetEmailPricingReportClick: (vehicle, svocId) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service_widget',
          eventAction: 'submit',
          eventResult: 'cta::submit::send-me-a-copy::email',
          eventNonInteractive: true,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId)
          }
        }
      };
    },
    frrWidgetGetServiceEstimateClick: (vehicle, svocId) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service_widget',
          eventAction: 'submit',
          eventResult: 'get-service-quote',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId)
          }
        }
      };
    },
    frrWidgetGetServiceEstimateLeadClick: (vehicle, svocId) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service_widget',
          eventAction: 'submit',
          eventResult: 'lead::service-quote::cta',
          eventNonInteractive: true,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocId)
          }
        }
      };
    },
    fireServiceOfferClick: (eventResult, nonInteractive = false) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: eventResult,
          eventNonInteractive: nonInteractive
        }
      };
    },
    serviceOfferCtaClick: (dealer, position) => {
      const { list, ...products } = ga.constructEcomData(
        'service-offer',
        'ecom_service-repair',
        dealer?.type,
        dealer?.provider,
        dealer?.svocId,
        dealer?.distance,
        position
      );
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-click',
          eventResult: 'phone-call::service-offer::cta',
          eventNonInteractive: true,
          ecommerce: {
            click: {
              actionField: {
                list
              },
              products: [products]
            }
          }
        }
      };
    },
    serviceOfferEmailCtaClick: (dealer, position) => {
      const { list, ...products } = ga.constructEcomData(
        'dealer-contact',
        'ecom_service-repair',
        dealer?.type,
        dealer?.provider,
        dealer?.svocId,
        dealer?.distance,
        position
      );
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-click',
          eventResult: 'lead::service-offer::email::cta',
          eventNonInteractive: true,
          ecommerce: {
            click: {
              actionField: {
                list
              },
              products: [products]
            }
          }
        }
      };
    },
    serviceOfferPhoneCallClick: (dealer, position) => {
      const { svocId, provider, type, distance } = dealer || {};
      return blasterSelectedDealerEcomPhoneClick({
        ecomIdName: 'phone-call',
        svocId,
        provider,
        type,
        distance,
        position
      });
    },
    // GA360 eCom - Tab "Dealer Link GA Events - eCom" - Event ID "2"
    serviceOfferDealerLinkEcomClick: (ecomIdName, type, provider, svocId, distance, cardIndex, isAlpha, isSpotlight) => {
      const dealerTypeOrPosition = isAlpha ? 'h' : isSpotlight ? 's' : cardIndex;
      const { list, ...products } = ga.constructEcomData(
        ecomIdName,
        'ecom_service-repair',
        type,
        provider,
        svocId,
        distance,
        cardIndex
      );
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-click',
          eventResult: `service-offer::schedule-appt::${dealerTypeOrPosition}::dlr-lnk`,
          eventNonInteractive: false,
          ecommerce: {
            click: {
              actionField: {
                list
              },
              products: [products]
            }
          }
        }
      };
    },
    serviceQuoteCtaClick: (dealer, position) => {
      const { list, ...products } = ga.constructEcomData(
        'dealer-contact',
        'ecom_service-repair',
        dealer?.type,
        dealer?.provider,
        dealer?.svocId,
        dealer?.distance,
        position
      );
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-click',
          eventResult: 'lead::service-quote::cta::list',
          eventNonInteractive: false,
          ecommerce: {
            click: {
              actionField: {
                list
              },
              products: [products]
            }
          }
        }
      };
    },
    serviceOfferEcomClick: (dealer, position) => {
      const { list, ...products } = ga.constructEcomData(
        'service-offer',
        'ecom_service-repair',
        dealer?.type,
        dealer?.provider,
        dealer?.svocId,
        dealer?.distance,
        position
      );
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-click',
          eventResult: 'phone-call::service-offer::cta',
          eventNonInteractive: true,
          ecommerce: {
            click: {
              actionField: {
                list
              },
              products: [products]
            }
          }
        }
      };
    },
    serviceOfferEcomEmailShareClick: (dealer, position) => {
      const { list, ...products } = ga.constructEcomData(
        'dealer-contact',
        'ecom_service-repair',
        dealer?.type,
        dealer?.provider,
        dealer?.svocId,
        dealer?.distance,
        position
      );
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-click',
          eventResult: 'lead::service-offer::email::cta',
          eventNonInteractive: true,
          ecommerce: {
            click: {
              actionField: {
                list
              },
              products: [products]
            }
          }
        }
      };
    },
    getSmartServiceAdviceCtaClick: (source) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `cta::get-smart-service-advice_${source}`,
          eventNonInteractive: true
        }
      };
    },
    serviceOffersNearYouDealerCardsClick: (vehicle, source, svocid, provider, type) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `service-offer::${ga.normalizedString(source)}`,
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocid, provider, null, type)
          }
        }
      };
    },
    serviceOfferNearYouEmailShareCtaClick: (vehicle, svocid, provider, type) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'cta::service-dealer-near-you::email-share',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocid, provider, null, type)
          }
        }
      };
    },
    serviceOfferNearYouPhoneLinkClick: (vehicle, svocid, provider, type) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'lead::phone',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocid, provider, null, type)
          }
        }
      };
    },
    // GA360 - Tab "Service Offers" - Event ID "22"
    serviceOfferDealerLinkClick: (vehicle, svocid, provider, type) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'lead::service-offer::dlr-lnk',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle, svocid, provider, null, type)
          }
        }
      };
    },
    checkMyRepairRiskCtaClick: (source) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `cta:check-my-repair-risk_${source}`,
          eventNonInteractive: true
        }
      };
    },
    lcpSelectVehicle: (vehicle) => {
      // Lean Repair Category Pages, US928428
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'cta::select-vehicle',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle)
          }
        }
      };
    },
    safetyRecallsFoundClick: (numOfRecalls) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `cta::${numOfRecalls}_safety-recalls-found`,
          eventNonInteractive: true
        }
      };
    },
    // this will need to be cleaned up during predictive repairs.
    riskOfMajorRepairWithMileageClick: (mileageRange) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `your-risk-of-major-repair_${mileageRange}`,
          eventNonInteractive: true
        }
      };
    },
    // this will need to be cleaned up during predictive repairs.
    repairCostForecastWithMileageClick: (mileageRange) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `mileage::your-repair-forecast_${mileageRange}`,
          eventNonInteractive: true
        }
      };
    },
    seeServicePriceSubmit: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'submit',
          eventResult: 'cta::see::service::price',
          eventNonInteractive: false
        }
      };
    },
    mostCommonServicesClick: (commonService) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `most-common-services::${commonService}`,
          eventNonInteractive: true
        }
      };
    },
    unsureServiceOrRepairClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'text-link::unsure-service-repair',
          eventNonInteractive: false
        }
      };
    },
    getMyAnswerClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'cta::get-my-answer',
          eventNonInteractive: false
        }
      };
    },
    commonProblemsAnchorTabClick: (tab) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `tab::common-problems::${tab}`,
          eventNonInteractive: false
        }
      }
    },
    editMileageCtaClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: `cta::submit::edit-mileage`,
          eventNonInteractive: false
        }
      }
    },
    dealerRepairPricingAccordionClick: () => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'dlr_repair-pricing_acr',
          eventNonInteractive: false
        }
      };
    },
    independentRepairPricingAccordionClick: () => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'ind_repair-pricing_acr',
          eventNonInteractive: false
        }
      };
    },
    saveMyCarSaveVehicleClick: (saveEvent, vehicle) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'save-car',
          eventAction: 'save',
          eventResult: `save_${saveEvent}::vehicle`,
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle)
          }
        }
      };
    },
    saveMyCarDeleteVehicleClick: (vehicle) => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'save-car',
          eventAction: 'save',
          eventResult: 'remove::saved-vehicle',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle)
          }
        }
      };
    },
    recallAlertsSmsConsentClick: () => {
      return {
        inherit: false,
        props: {
          event: 'analytics_event',
          eventElement: 'service',
          eventAction: 'click',
          eventResult: 'sms-consent',
          eventNonInteractive: false
        }
      };
    }
  },
  impressions: {
    dealerCardImpression: (ecomIdName, type, provider, svocId, distance, cardIndex) => {
      return {
        event: 'ecom_event',
        eventElement: 'service-and-repair',
        eventAction: 'ecom-impression',
        eventResult: 'dealer-card::impression',
        eventNonInteractive: true,
        ecommerce: {
          impressions: [
            ga.constructEcomData(ecomIdName, 'ecom_service-repair', type, provider, svocId, distance, cardIndex)
          ]
        }
      };
    },
    noCommonProblemsImpression: (vehicle) => {
      return {
        inherit: true,
        props: {
          event: 'analytics_event',
          eventElement: 'service-and-repair',
          eventAction: 'impressions',
          eventResult: 'insuficient-data-no-common-problems',
          eventNonInteractive: false,
          eventData: {
            vehicle: ga.constructVehicle(vehicle)
          }
        }
      };
    },
    recallBlasterSelectedDealerImpression: (ecomIdName, svocId, provider, type, distance, position) => {
      return {
        event: 'ecom_event',
        eventElement: 'service-and-repair',
        eventAction: 'ecom-impression',
        eventResult: 'phone-call::list',
        eventNonInteractive: true,
        ecommerce: {
          impressions: [
            ga.constructEcomData(ecomIdName, 'ecom_service-repair', type, provider, svocId, distance, position)
          ]
        }
      };
    },
    recallBlasterPhoneCallSatImpression: (ecomIdName, svocId, provider, type, distance, position) => {
      return {
        event: 'ecom_event',
        eventElement: 'service-and-repair',
        eventAction: 'ecom-impression',
        eventResult: 'phone-call::list::sat',
        eventNonInteractive: true,
        ecommerce: {
          impressions: [
            ga.constructEcomData(ecomIdName, 'ecom_service-repair', type, provider, svocId, distance, position)
          ]
        }
      };
    },
    serviceQuotePhoneCallSatImpression: (ecomIdName, svocId, provider, type, distance, position) => {
      return {
        event: 'ecom_event',
        eventElement: 'service-and-repair',
        eventAction: 'ecom-impression',
        eventResult: 'phone-call::list::sat::service',
        eventNonInteractive: true,
        ecommerce: {
          impressions: [
            ga.constructEcomData(ecomIdName, 'ecom_service-repair', type, provider, svocId, distance, position)
          ]
        }
      };
    },
    serviceQuoteSelectedDealerImpression: (ecomIdName, svocId, provider, type, distance, position) => {
      return {
        event: 'ecom_event',
        eventElement: 'service-and-repair',
        eventAction: 'ecom-impression',
        eventResult: 'phone-call::list',
        eventNonInteractive: true,
        ecommerce: {
          impressions: [
            ga.constructEcomData(ecomIdName, 'ecom_service-repair', type, provider, svocId, distance, position)
          ]
        }
      };
    },
    serviceOfferEcomImpression: (dealer, position) => {
      return {
        props: {
          event: 'ecom_event',
          eventElement: 'service-and-repair',
          eventAction: 'ecom-impression',
          eventResult: 'phone-call::service-offer',
          eventNonInteractive: true,
          ecommerce: {
            impressions: [
              ga.constructEcomData(
                'service-offer',
                'ecom_service-repair',
                dealer?.type,
                dealer?.provider,
                dealer?.svocId,
                dealer?.distance,
                position
              )
            ]
          }
        }
      };
    }
  }
};

const blasterSubmitClick = ({ eventResultName, hasSelectedDealers }) => ({
  inherit: false,
  props: {
    event: 'analytics_event',
    eventElement: 'service',
    eventAction: 'submit',
    eventResult: `lead:${eventResultName ? `:${eventResultName}:` : ''}:cta::${hasSelectedDealers ? 'list' : 'no-list'}`,
    eventNonInteractive: false
  }
});

const blasterSubmitEcomClick = ({ eventResultName, ecomIdName, svocId, provider, type, distance, position }) => {
  const { list, ...products } = ga.constructEcomData(
    ecomIdName,
    'ecom_service-repair',
    type,
    provider,
    svocId,
    distance,
    position
  );
  return {
    props: {
      event: 'ecom_event',
      eventElement: 'service-and-repair',
      eventAction: 'ecom-click',
      eventResult: `lead:${eventResultName ? `:${eventResultName}:` : ''}:cta::list`,
      eventNonInteractive: false,
      ecommerce: {
        click: {
          actionField: {
            list
          },
          products: [products]
        }
      }
    }
  };
};

const blasterSelectedDealerEcomPhoneClick = ({ ecomIdName, svocId, provider, type, distance, position }) => {
  const { list, ...products } = ga.constructEcomData(
    ecomIdName,
    'ecom_service-repair',
    type,
    provider,
    svocId,
    distance,
    position
  );
  return {
    props: {
      event: 'ecom_event',
      eventElement: 'service-and-repair',
      eventAction: 'ecom-click',
      eventResult: 'phone-call::cta::list',
      eventNonInteractive: false,
      ecommerce: {
        click: {
          actionField: {
            list
          },
          products: [products]
        }
      }
    }
  };
};

const blasterPhoneCallSatEcomClick = ({ eventResultName, ecomIdName, svocId, provider, type, distance, position }) => {
  const { list, ...products } = ga.constructEcomData(
    ecomIdName,
    'ecom_service-repair',
    type,
    provider,
    svocId,
    distance,
    position
  );
  return {
    props: {
      event: 'ecom_event',
      eventElement: 'service-and-repair',
      eventAction: 'ecom-click',
      eventResult: `phone-call::cta::list::sat${eventResultName ? `::${eventResultName}` : ''}`,
      eventNonInteractive: false,
      ecommerce: {
        click: {
          actionField: {
            list
          },
          products: [products]
        }
      }
    }
  };
};

const blasterSelectedDealerPhoneClick = ({ eventResultName, vehicle, svocId, provider, subProvider = null, type }) => ({
  inherit: true,
  props: {
    event: 'analytics_event',
    eventElement: 'service',
    eventAction: 'submit',
    eventResult: `lead::phone:${eventResultName ? `:${eventResultName}:` : ''}:list`,
    eventNonInteractive: false,
    eventData: {
      vehicle: ga.constructVehicle(vehicle, svocId, provider, subProvider, type)
    }
  }
});

export default ga;
