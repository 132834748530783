import { gql } from '@apollo/client';

export default gql`
  query commonProblems($make: String!, $year: Int, $model: String) {
    commonProblems(make: $make, year: $year, model: $model) {
      risk
      repairThreshold
      fiveYearSpendingPrediction
      services {
        vinCount
        relativePricing
        type
        startDate
        endDate
      }
    }
  }
`;
