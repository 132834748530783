import React, { useState } from 'react';
import loadable from '@loadable/component';
import retryImport from '@kbbsdk/global-sdk/utils/retryImport';
import styled from '@emotion/styled';
import { ContentBlock, typography, FlexBox, colors, size, breakpoints } from '@argo/principles';
import { Disclaimer, Image, Link, InfoIcon, Button, Heading } from '@argo/ui-elements';
import { mileageIncrement, getFriendlyTypicalMileage } from '../utils/getFriendlyOdometerReadings';
import { getWinDataLayer } from '@kbbhydra/utils';
import rmrDataAnalytics from '../../utils/rmrDataAnalytics';
import { useAdaptiveRender } from '@argo/utils';

const AboutServiceAndRepairDataOverlay = loadable(() =>
  retryImport(() => import('./Overlay/AboutServiceAndRepairDataOverlay'))
);

const StyledHeading = styled(Heading)`
  margin-bottom: ${size.xs}px;
  display: flex;
  ${(props) =>
    props.headingName === 'ContentHeadingExtra'
      ? `
      justify-content: center;
      @media (max-width: ${breakpoints.md}px) {
        justify-content: flex-start;
      }
      `
      : `justify-content: flex-start`}
`;

const StyledMilesConditionsNote = styled.p`
  margin-bottom: ${size.sm}px;
  color: ${colors.primary.black};
  ${(props) =>
    props.subHeadingTypography === 'XSSemi' ? `${typography.defaults['XSSemi']}` : `${typography.defaults['SSemi']}`}
`;

const StyledRiskImage = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  min-height: 125px;
  align-items: center;
`;

const StyledLineHeight = styled.div`
  max-width: 480px;
  line-height: normal;
`;

const StyledLineHeight2 = styled.div`
  max-width: 480px;
  line-height: normal;
  margin-bottom: 0px;
`;

const RiskImageTextContainer = styled.div`
  position: absolute;
  top: 80px;
  right: ${(props) =>
    props.isMobileViewport || (props.hasCustomImageConstraint && props.isCustomImageConstraint) ? '80' : '100'}px;
`;

const RiskText = styled.p`
  ${typography.defaults['MSemi']};
  margin-top: -3px;
  color: ${colors.primary.black};
`;

const RiskOfMajorRepairText = styled.p`
  ${typography.types['body']};
  margin-top: -5px;
  font-size: ${size.xs12}px;
  font-weight: 600;
  color: ${colors.primary.black};
`;

const VehicleMileageHeader = styled.div`
  ${typography.types['body']};
  font-size: ${size.xs12}px;
  color: ${colors.primary.charcoal};
  margin-top: -5px;
  margin-bottom: ${size.xs12}px;
`;

const VehicleMileageStatement = styled.div`
  ${typography.types['body']};
  font-size: ${size.xs12}px;
  inline-size: fit-content;
  color: ${colors.primary.charcoal};
  margin-bottom: ${size.sm}px;
  line-height: 1.5;
  text-align: center;
  width: 100%;
`;

const StyledRiskImageContainer = styled.div`
  position: relative;
`;

const StyledBox = styled.div`
  background-color: transparent;
  display: flex;
  height: 100%;
  margin: 0px;
`;

const StyledDisclaimer = styled(Disclaimer)`
  margin-bottom: ${size.xs}px;
`;

const StyledButton = styled(Button)`
  margin: ${size.xs}px 0 ${size.xs}px 0;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const InfoIconLink = ({ setOpenOverlay }) => {
  const InfoIconWrapper = styled.span`
    :hover {
      cursor: pointer;
    }
  `;
  return (
    <InfoIconWrapper
      data-testid="infoIcon"
      onClick={(e) => {
        e.preventDefault();
        setOpenOverlay(true);

        if (typeof caiEventBus !== 'undefined') {
          caiEventBus.emit('riskMajorRepair:event', {
            eventMessage: 'infoIcon',
            eventAction: 'click'
          });
        }
      }}
    >
      <InfoIcon color="darkBrightBlue" size="16" />
    </InfoIconWrapper>
  );
};

export const repairRiskImagePicker = (risk) => {
  if (risk === 'Low') {
    return 'https://file.kbb.com/kbb/images/ownership/CommonProblems/Carousel/bgBandLow.png';
  }
  if (risk === 'Avg') {
    return 'https://file.kbb.com/kbb/images/ownership/CommonProblems/Carousel/bgBandMed.png';
  }
  if (risk === 'High') {
    return 'https://file.kbb.com/kbb/images/ownership/CommonProblems/Carousel/bgBandHigh.png';
  }
};

const riskArchAndParagraphText = (risk, textOnly) => {
  if (risk === 'Low') {
    if (textOnly) return 'Low';
    return <RiskText>Low</RiskText>;
  }
  if (risk === 'Avg') {
    if (textOnly) return 'Medium';
    return <RiskText>Medium</RiskText>;
  }
  if (risk === 'High') {
    if (textOnly) return 'High';
    return <RiskText>High</RiskText>;
  }
};

const ChartFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const StyledMileage = styled.span`
  font-size: ${size.xs12}px;
  font-weight: 600;
  color: black;
`;

const handleAnalyticsCheckServiceEstimates = (analytics) => {
  rmrDataAnalytics.fireRmrClick(analytics, rmrDataAnalytics.clicks.handleServiceQuoteGetEstimatesClick());
};

const MajorRepairRiskInfo = ({
  make,
  model,
  year,
  risk,
  repairThreshold,
  onClick,
  vehicleInfo,
  typicalMileage,
  commonProblemsHref,
  shadowRoot,
  buttonType,
  buttonWidth,
  headingName,
  subHeadingTypography
}) => {
  const [openOverlay, setOpenOverlay] = useState(false);

  const initialLower = getFriendlyTypicalMileage(typicalMileage);
  const initialUpper = initialLower + mileageIncrement;

  const [mileage, setMileage] = useState({
    lower: initialLower,
    upper: initialUpper,
    text: `${initialLower / 1000}-${initialUpper / 1000}K Miles`
  });

  const rmrDataLayer = getWinDataLayer();

  const { width } = useAdaptiveRender();

  const isMobileViewport = width < breakpoints.md;

  // hasCustomImageConstraint is now only used for my accounts integration
  // due to how webcomponent sits on my-accounts app, customImageConstraint is needed
  // integration with other apps should pass in hasCustomImageConstraint as prop (future change)
  // isCustomImageConstraint will vary depending if other apps need image constraints
  const hasCustomImageConstraint = true;
  const isCustomImageConstraint = breakpoints.xl <= width && width <= breakpoints.max - 1;

  return (
    <>
      <StyledBox contentPadding="extraSmall">
        <ChartFlexBox flowColumn justifyContent="space-between" alignItems="stretch" flexWrap="wrap">
          <StyledLineHeight>
            <StyledHeading
              headingTag="h2"
              headingName={headingName ? headingName : 'SubsectionHeadingExtra'}
              textTransformation={true}
            >
              Your Risk of Major Repair
            </StyledHeading>
            <StyledMilesConditionsNote subHeadingTypography={subHeadingTypography}>
              Likelihood of your vehicle needing a major repair. Your risks may vary.
            </StyledMilesConditionsNote>
          </StyledLineHeight>
          <StyledRiskImage>
            <StyledRiskImageContainer>
              <Image
                src={repairRiskImagePicker(risk)}
                alt="SR Price Advisor No Price"
                cssWidth={isMobileViewport || (hasCustomImageConstraint && isCustomImageConstraint) ? '280px' : '320px'}
                cssHeight={
                  isMobileViewport || (hasCustomImageConstraint && isCustomImageConstraint) ? '140px' : '160px'
                }
                isLazy
              />
              <RiskImageTextContainer
                hasCustomImageConstraint={hasCustomImageConstraint}
                isCustomImageConstraint={isCustomImageConstraint}
                isMobileViewport={isMobileViewport}
              >
                <FlexBox flowColumn alignItems="center" verticalSpacing="micro">
                  <InfoIconLink setOpenOverlay={setOpenOverlay} />
                  {riskArchAndParagraphText(risk)}
                  <RiskOfMajorRepairText>Risk of Major Repair</RiskOfMajorRepairText>
                </FlexBox>
              </RiskImageTextContainer>
            </StyledRiskImageContainer>
          </StyledRiskImage>
          <VehicleMileageStatement>
            Vehicles like yours with <StyledMileage>{mileage.text.toLowerCase()}</StyledMileage> have a{' '}
            <StyledMileage>{riskArchAndParagraphText(risk, true)} Risk of Major Repair.</StyledMileage>
          </VehicleMileageStatement>
          <StyledLineHeight2>
            <StyledDisclaimer>
              {`Like any future forecast, this may not apply to your specific vehicle. Across all ${make} vehicles,
                a repair over ${repairThreshold} would be considered "major". `}
              <Link
                href="javascript:void(0)"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenOverlay(true);

                  if (typeof caiEventBus !== 'undefined') {
                    caiEventBus.emit('riskMajorRepair:event', {
                      eventMessage: 'moreAboutData',
                      eventAction: 'click'
                    });
                  }
                }}
              >
                More about our data
              </Link>
            </StyledDisclaimer>
            <StyledButtonContainer>
              <StyledButton
                buttonType={buttonType ? buttonType : 'secondary'}
                text="Get Service Estimates"
                horizontalPadding="0"
                minimizeSize={false}
                disabled={false}
                href={commonProblemsHref}
                target="_self"
                rel="nofollow"
                enableCapitalize={false}
                sizeType="regular"
                width={buttonWidth ? buttonWidth : '100%'}
                preventTextWrap
                onClick={(e) => {
                  onClick(e);
                  handleAnalyticsCheckServiceEstimates(rmrDataLayer);
                }}
              />
            </StyledButtonContainer>
          </StyledLineHeight2>
        </ChartFlexBox>
      </StyledBox>
      <AboutServiceAndRepairDataOverlay
        setOpenOverlay={setOpenOverlay}
        openOverlay={openOverlay}
        shadowRoot={shadowRoot}
        isMobileViewport={isMobileViewport}
      />
    </>
  );
};

export default MajorRepairRiskInfo;
