import React from 'react';
import { Overlay } from '@argo/base-patterns';
import { ContentBlock, typography, colors } from '@argo/principles';
import { List, ListItem, Paragraph } from '@argo/ui-elements';
import styled from '@emotion/styled';

const StyledParagraph = styled.p`
  ${typography.types.body};
  color: ${colors.primary.charcoal};
  text-align: left;
`;

const AboutServiceAndRepairDataOverlay = ({ openOverlay, setOpenOverlay, isMobileViewport, shadowRoot }) => (
  <Overlay
    widthRatio={isMobileViewport ? 90 : 70}
    maxHeightRatio={90}
    maxWidth="807px"
    showOverlay={openOverlay}
    onClose={() => setOpenOverlay(false)}
    header="About Our Service &amp; Repair Data"
    headingTag="h4"
    container={shadowRoot}
  >
    <ContentBlock verticalSpacing="sm">
      <StyledParagraph>
        Our data comes from real repair orders for these vehicles at franchise dealers that still sell that make. It
        helps us predict a major repair or future service costs, but there are a few limitations:
      </StyledParagraph>
    </ContentBlock>
    <List withinParagraph listType="unordered">
      <ListItem>
        If vehicles are too new (and don't have a history) or older than 7 years, there is less data available.
      </ListItem>
      <ListItem>
        The data assumes you drive a standard number of miles in average weather and road conditions. The info may not
        apply if your mileage or conditions aren't typical.
      </ListItem>
      <ListItem>
        The data doesn't cover repairs or maintenance that happens at independent repair shops or if the owner did the
        maintenance themselves.
      </ListItem>
    </List>
  </Overlay>
);

export default AboutServiceAndRepairDataOverlay;
