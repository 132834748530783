import React from 'react';
import MajorRepairRiskInfo from './MajorRepairRiskInfo';
import styled from '@emotion/styled';
import { FlexBox, size, breakpoints } from '@argo/principles';
import { useCommonProblems, useModelQuery, friendlyUrl } from '@kbbhydra/utils';

const StyledFlexBox = styled(FlexBox)`
  width: 100%;
  height: 100%;
  margin: 8px 0 8px 0;
`;

const MajorRepairServiceContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 280px;
  @media (max-width: ${breakpoints.md}px) {
    height: 100%;
  }
`;

const sendRmrDataStatus = (eventMessage) => {
  caiEventBus.emit('riskMajorRepair:data', {
    eventMessage: eventMessage
  });
};

const MajorRepairServiceInfo = ({
  year,
  make,
  model,
  client,
  vehicleid,
  shadowRoot,
  buttonType,
  buttonWidth,
  headingName,
  subHeadingTypography
}) => {
  const {
    data: vehicleInfo,
    loading: modelQueryLoading,
    errors: modelQueryErrors
  } = useModelQuery({ client, year, make, model, vehicleid });

  const {
    data: commonProblemsData,
    loading: commonProblemsLoading,
    errors: commonProblemsErrors
  } = useCommonProblems({ client, year: vehicleInfo?.yearId, make, model: vehicleInfo?.modelName });

  const risk = commonProblemsData?.risk;
  const repairThreshold = commonProblemsData?.repairThreshold;
  const typicalMileage = vehicleInfo?.typicalMileage;

  if (!modelQueryLoading && !commonProblemsLoading && typeof caiEventBus !== 'undefined') {
    if (commonProblemsErrors || modelQueryErrors) sendRmrDataStatus('error');
    else if (commonProblemsData && risk && repairThreshold) {
      sendRmrDataStatus('success');
    }
  }

  if ((commonProblemsData === null || risk === null) && typeof caiEventBus !== 'undefined') {
    sendRmrDataStatus('noData');
  }

  const getCommonProblemsHref = (vehicleInfo) => {
    const make = friendlyUrl(vehicleInfo?.makeName);
    const model = friendlyUrl(vehicleInfo?.modelName);
    const year = vehicleInfo?.yearId;
    const vehicleId = vehicleid;
    const baseOrigin = 'https://www.kbb.com';

    const href = `${baseOrigin}/${make}/${model}/${year}/common-problems/`;

    return vehicleId ? href + `?vehicleid=${vehicleId}#service-quote` : `${href}#service-quote`;
  };

  const commonProblemsHref = getCommonProblemsHref(vehicleInfo);

  const handleGetServiceEstimatesClick = (e) => {
    if (typeof caiEventBus !== 'undefined') {
      caiEventBus.emit('riskMajorRepair:event', {
        eventMessage: 'getServiceEstimates',
        eventAction: 'submit'
      });
    }
  };

  const RepairRiskServiceCostCards = () => {
    return (
      <StyledFlexBox data-testid="RiskOfMajorRepair" justifyContent="center" default={['1', '1']} sm={['1', '1']}>
        {typicalMileage && risk && repairThreshold && (
          <MajorRepairRiskInfo
            make={make}
            model={model}
            year={year}
            risk={risk}
            repairThreshold={repairThreshold}
            vehicleInfo={vehicleInfo}
            typicalMileage={typicalMileage}
            commonProblemsHref={commonProblemsHref}
            shadowRoot={shadowRoot}
            onClick={handleGetServiceEstimatesClick}
            buttonType={buttonType}
            buttonWidth={buttonWidth}
            headingName={headingName}
            subHeadingTypography={subHeadingTypography}
          />
        )}
      </StyledFlexBox>
    );
  };

  return (
    risk &&
    repairThreshold && (
      <MajorRepairServiceContainer>
        <RepairRiskServiceCostCards />
      </MajorRepairServiceContainer>
    )
  );
};

export default MajorRepairServiceInfo;
