import { gql } from '@apollo/client';

export default gql`
  query modelPage($year: String, $make: String, $model: String, $vehicleid: String) {
    vehicle(year: $year, makeName: $make, modelName: $model, vehicleId: $vehicleid) {
      yearId
      makeId
      makeName
      modelId
      modelName
      modelMarketName
      servicePhoneNumber
      chromeStyleIds
      typicalMileage
      bestImage
      defaultVehicleId
      selectedVehicleId
      relatedVehicleIds
      trims {
        defaultVehicleId
        trimName
        relatedVehicleIds
        chromeStyleIds
      }
    }
  }
`;
