import { useQuery } from '@apollo/client';
import commonProblems from '../tags/commonProblems';

const useCommonProblems = ({ client, year, make, model, skip: doSkip }) => {
  const skip = doSkip || !(year && make && model);

  const { data: commonProblemsData, error } = useQuery(commonProblems, {
    skip,
    variables: {
      year,
      make,
      model
    },
    ssr: true,
    client
  });

  const data = commonProblemsData?.commonProblems;

  return {
    data: commonProblemsData?.commonProblems,
    errors: error,
    loading: !(skip || data || error)
  }
}

export default useCommonProblems;
