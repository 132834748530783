import React from 'react';
import ReactDom from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import { RiskMajorRepairReactComponent } from '@kbbhydra/risk-major-repair-react-component';
import createCache from '@emotion/cache';

const generateEmotionCache = (section) =>
  createCache({
    key: 'kbb-hydra-riskmajorrepair',
    container: section,
    prepend: true
  });

class RiskMajorRepairWebComponent extends HTMLElement {
  static get observedAttributes() {
    return ['year', 'make', 'model', 'vehicleid'];
  }

  constructor() {
    super();

    this.section = document.createElement('section');
    this.main = document.createElement('main');
    this.section.appendChild(this.main);
    this.attachShadow({ mode: 'open' }).appendChild(this.section);
    this.root = ReactDom.createRoot(this.main);

    this._year = this.getAttribute('year');
    this._make = this.getAttribute('make');
    this._model = this.getAttribute('model');
    this._vehicleid = this.getAttribute('vehicleid');
  }

  connectedCallback() {
    const urlParams = new URLSearchParams(window.location.search.toLowerCase());
    const yearQueryString = urlParams.get('year');
    const makeQueryString = urlParams.get('make');
    const modelQueryString = urlParams.get('model');
    const sourceQueryString = urlParams.get('source');
    const vehicleIdQueryString = urlParams.get('vehicleid');
    const buttonTypeQueryString = urlParams.get('buttontype');
    const buttonWidthQueryString = urlParams.get('buttonwidth');
    const subHeadingTypographyQueryString = urlParams.get('subheadingtypography');
    const headingNameQueryString = urlParams.get('headingname');

    const year = yearQueryString ? yearQueryString : this.getAttribute('year');
    const make = makeQueryString ? makeQueryString : this.getAttribute('make');
    const model = modelQueryString ? modelQueryString : this.getAttribute('model');
    const source = sourceQueryString ? sourceQueryString : this.getAttribute('souce');
    const vehicleid = vehicleIdQueryString ? vehicleIdQueryString : this.getAttribute('vehicleid');
    const buttonType = buttonTypeQueryString ? buttonTypeQueryString : this.getAttribute('buttontype');
    const buttonWidth = buttonWidthQueryString ? buttonWidthQueryString : this.getAttribute('buttonwidth');
    const subHeadingTypography = subHeadingTypographyQueryString
      ? subHeadingTypographyQueryString
      : this.getAttribute('subheadingtypography');
    const headingName = headingNameQueryString ? headingNameQueryString : this.getAttribute('headingname');

    this.render();

    if (typeof caiEventBus !== 'undefined') {
      const dealerDataFromMitt = caiEventBus.on('dataFromMitt', (arg) => console.log(arg));
    }
    if (this.main.isConnected) {
      if (typeof caiEventBus !== 'undefined') {
        caiEventBus.emit('RiskMajorRepairConnected');
      }
    }
  }

  get year() {
    return this._year;
  }

  set year(value) {
    this._year = value;
  }

  get make() {
    return this._make;
  }

  set make(value) {
    this._make = value;
  }

  get model() {
    return this._model;
  }

  set model(value) {
    this._model = value;
  }

  get vehicleid() {
    return this._vehicleid;
  }

  set vehicleid(value) {
    this._vehicleid = value;
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'year') {
      this.year = newValue;
      this.render();
    }

    if (name === 'make') {
      this.make = newValue;
      this.render();
    }

    if (name === 'model') {
      this.model = newValue;
      this.render();
    }

    if (name === 'vehicleid') {
      this.vehicleid = newValue;
      this.render();
    }
  }

  render() {
    const buttonWidth = this.getAttribute('buttonwidth');
    const buttonType = this.getAttribute('buttontype');
    const headingName = this.getAttribute('headingname');
    const subHeadingTypography = this.getAttribute('subheadingtypography');
    const source = this.getAttribute('source');

    this.root.render(
      <CacheProvider value={generateEmotionCache(this.section)}>
        <RiskMajorRepairReactComponent
          year={this.year}
          make={this.make}
          model={this.model}
          vehicleid={this.vehicleid}
          source={source}
          shadowRoot={this.shadowRoot}
          buttonType={buttonType}
          buttonWidth={buttonWidth}
          headingName={headingName}
          subHeadingTypography={subHeadingTypography}
        />
      </CacheProvider>
    );
  }
}

customElements.define('risk-major-repair-web', RiskMajorRepairWebComponent);
