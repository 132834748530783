import { Analytics as ga } from '@kbbhydra/analytics';
const toGtmString = (value) => (value ? value.toString() : undefined);

const rmrDataAnalytics = {
  getEvent: (event) => {
    return {
      ...event
    };
  },

  fireRmrClick: (dataLayer, event) => {
    dataLayer.push({
      ...rmrDataAnalytics.getEvent(event)
    });
  },

  defaultValues: {
    vehicle: {
      catalog: 'kbb'
    }
  },
  normalizedStringWithCharLimit: (string = '', charLimit = 25) => {
    const parsedPromo = string
      .replace(/[.,/#!$%^&*;:{}=\-_`~"()]/g, ' ')
      .split(' ')
      .filter((ele) => ele !== '');

    const normalizedArray = [];
    let charCount = 0;
    parsedPromo.forEach((ele) => {
      const lowerCaseEle = ele.toLowerCase();
      if (charCount >= charLimit) {
        return;
      }
      if (charCount < charLimit) {
        normalizedArray.push(lowerCaseEle.slice(0, charLimit - charCount));
      }
      charCount += ele.length;
    });
    return normalizedArray.join('-');
  },

  mapVehicleInfo: (vehicleInfo) => {
    return {
      year: toGtmString(vehicleInfo?.YearId),
      makeId: toGtmString(vehicleInfo?.MakeId),
      makeName: toGtmString(vehicleInfo?.MakeName),
      modelId: toGtmString(vehicleInfo?.ModelId),
      modelName: toGtmString(vehicleInfo?.ModelName),
      trimName: toGtmString(vehicleInfo?.TrimName),
      vehicleId: toGtmString(vehicleInfo?.VehicleId),
      vin: toGtmString(vehicleInfo?.Vin),
      chromeStyleId:
        vehicleInfo?.ChromeStyleIds && vehicleInfo?.ChromeStyleIds.length > 0
          ? toGtmString(vehicleInfo.ChromeStyleIds[0])
          : undefined
    };
  },

  constructVehicle: (vehicleInfo = null, svocId = null, provider = null, subProvider = null, type = null, defaultValues = false) => {
    const defaults = defaultValues ? rmrDataAnalytics.defaultValues.vehicle : {};
    const vehicle = vehicleInfo ? rmrDataAnalytics.mapVehicleInfo(vehicleInfo) : {};
    return {
      ...defaults,
      ...vehicle,
      dealershipId: toGtmString(svocId),
      customerType: provider && provider.toLowerCase(),
      priorityType: type
    };
  },
  clicks: {
    handleServiceQuoteGetEstimatesClick: () => {
      const event = ga.clicks.serviceQuoteGetEstimatesClick();
      return event.props;
    },
  }
}

export default rmrDataAnalytics;
