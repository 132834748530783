import React from 'react';
import { Theme } from '@argo/principles';
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient';
import MajorRepairServiceInfo from './components/MajorRepairServiceInfo';

const RiskMajorRepairReactComponent = ({
  year,
  make,
  model,
  vehicleid,
  shadowRoot,
  buttonType,
  buttonWidth,
  headingName,
  subHeadingTypography,
  source
}) => {
  return (
    <ApolloProvider client={client}>
      <Theme noObservable>
        <MajorRepairServiceInfo
          year={year}
          make={make}
          model={model}
          vehicleid={vehicleid}
          client={client}
          shadowRoot={shadowRoot}
          buttonType={buttonType}
          buttonWidth={buttonWidth}
          headingName={headingName}
          subHeadingTypography={subHeadingTypography}
        />
      </Theme>
    </ApolloProvider>
  );
};

export default RiskMajorRepairReactComponent;
