export const getLastPathNameItem = (pathName) => {
  const pathNameArray = pathName.split('/').filter((item) => item !== '');
  return pathNameArray.pop();
};

const getPageData = (pageType, detailPageName) => {
  const pageData = {
    pageType: '',
    detailPageName: '',
    siteSection: ''
  };
  const siteSection = 'service';
  pageData.pageType = pageType;
  pageData.detailPageName = detailPageName;
  pageData.siteSection = siteSection;
  return pageData;
};

export const getGAPageProps = (routeName, pathName) => {
  switch (routeName) {
    case 'repair_slp':
      return getPageData(`slp_repair`, `slp_repair`);

    case 'repair_make':
      return getPageData(`slp_repair_make`, `slp_repair_make`);

    case 'service_slp':
      return getPageData(`slp_service`, `slp_service`);

    case 'service_make':
      return getPageData(`slp_service_make`, `slp_service_make`);

    case 'seorepair_slp':
      return getPageData(`slp_repair_dedicated`, `slp_repair_dedicated_${getLastPathNameItem(pathName)}`);

    case 'seorepair_make_slp':
      return getPageData(`make_repair_dedicated`, `make_repair_dedicated_${getLastPathNameItem(pathName)}`);

    case 'seorepair_ymm_slp':
      return getPageData(`ymm_repair_dedicated`, `ymm_repair_dedicated_${getLastPathNameItem(pathName)}`);

    case 'repair_ymm':
      return getPageData(`ymm_repair`, `ymm_repair`);

    case 'maintenance_schedules_ymm':
      return getPageData(`ymm_service`, `ymm_service`);

    case 'common_problems':
      return getPageData(`ymm_common-problems`, `ymm_common-problems`);

    case 'service_centers_slp':
      return getPageData(`slp_service-centers`, `slp_service-centers`);

    case 'service_centers_make':
      return getPageData(`make_service-centers`, `make_service-centers`);

    case 'service_centers_make_redirect':
      return getPageData(`make_service-centers-bot`, `make_service-centers-bot`);

    case 'service_centers_slp_redirect':
      return getPageData(`slp_service-centers-bot`, `slp_service-centers-bot`);

    case 'service_repair_guide':
      return getPageData(`slp_service-repair-guide`, `slp_service-repair-guide`);
      
    case 'service_base_advisor':
      return getPageData(`slp_service-base-advisor`, `slp_service-base-advisor`);

    case 'recall_slp':
      return getPageData(`slp_recall`, `slp_recall`);

    case 'recall_make':
      return getPageData(`slp_recall_make`, `slp_recall_make`);

    case 'recall_ymm':
      return getPageData(`ymm_recall`, `ymm_recall`);

    case 'schedule_service_redirect':
      return getPageData(`service_appt`, `service_appt_conf`);

    case 'schedule_service_redirect_err':
      return getPageData(`service_appt`, `service_appt_error_conf`);

    case 'lean_category_slp':
      return getPageData(`slp_lean_category`, `slp_lean_cat_${getLastPathNameItem(pathName)}`);

    default:
      return {};
  }
};
